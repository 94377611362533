import {
  Textbox,
  L,
  HT,
  ValidText,
  GuideText,
  ErrorText,
  WarningText,
  IC,
} from 'CORE__UI/forms/InputText/core__inputTextStyles';
import styled, { css } from 'styled-components';
import { FONT, GREYS, FUNCTIONAL } from '../globals/colours';
const L_JN = styled(L)`
  color: ${GREYS.white};
`;
const Textbox_JN = styled(Textbox)`
  background: ${FUNCTIONAL.documentUB};
  color: ${GREYS.white};
  border: none;
  border-radius: 20px;
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      background-color: ${GREYS.doveD};
      color: ${GREYS.black};
    `}
  ${({ inactive }) =>
    inactive &&
    css`
      background-color: ${FUNCTIONAL.documentUB};
      pointer-events: none;
      border: 1px solid ${GREYS.silver};
      color: ${FONT.lightMute};
    `}
`;
const HT_JN = styled(HT)`
  color: ${GREYS.doveD};
`;

const IC_Extended = styled(IC)`
  color: ${FONT.lightMute};
`;

export {
  Textbox_JN as Textbox,
  L_JN as L,
  HT_JN as HT,
  ValidText,
  GuideText,
  ErrorText,
  WarningText,
  IC_Extended as IC,
};
