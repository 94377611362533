import { CloseIcon } from 'CORE__UI/globals/core__modal-close-icon';
import styled from 'styled-components';
import { GREYS } from './colours';

const CloseIcon_OL = styled(CloseIcon)`
  color: ${GREYS.white};
  border: 1px solid ${GREYS.white};
  width: 24px;
  height: 24px;
  position: static;
  border-radius: 8px;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export { CloseIcon_OL as CloseIcon };
