import {
  SB,
  SecB,
  NeutralB,
} from 'CORE__UI/buttons/ModalButton/core__modalButtonStyles';
import styled, { css } from 'styled-components';
import { BRAND, GREYS } from '../globals/colours';
const SecB_OL = styled(SecB)`
  background: ${GREYS.silverD};
  &:hover,
  &:active,
  &:focus {
    color: ${GREYS.white};
    background-color: ${BRAND.primary};
  }
`;
const SB_JN = styled(SB)`
  color: ${GREYS.white};
  ${({ isBlocked }) =>
    isBlocked &&
    css`
      background: ${GREYS.dove};
      color: ${GREYS.white};
      border: none;
    `}
  ${({ showJNModal }) =>
    showJNModal
      ? css`
          color: ${GREYS.white};
          background: ${BRAND.secondary};
          &:hover {
            background: ${BRAND.secondary};
          }
        `
      : css`
          background: ${BRAND.favourite};
          color: ${GREYS.white};
          &:hover {
            background: ${BRAND.secondary};
          }
        `}
  border-radius: 20px;
  border: 1px solid ${GREYS.silver};
`;
export { SB_JN as SB, SecB_OL as SecB, NeutralB };
