import styled from 'styled-components';
import {
  H,
  D,
  S,
  C,
  E,
  MB,
  MStyles,
  ModalButtonClose,
  EYE,
  L,
  J,
  Image,
} from 'CORE__UI/apps/LoginApp/core__loginModal';
import { BRAND, GREYS } from '../../globals/colours';

const MStyles_OL = {
  modal: {
    ...MStyles.modal,
    backgroundColor: `${GREYS.silverD}`,
    borderRadius: 20,
    border: `1px solid ${GREYS.silver}`,
    width: 480,
    boxShadow: '0 16px 48px black',
  },
};
const S_OL = styled(S)`
  color: ${GREYS.white};
  background: ${GREYS.silverD};
`;
const D_OL = styled(D)`
  background: ${GREYS.silver};
`;
const C_OL = styled(C)`
  color: ${GREYS.white};
  a {
    color: ${GREYS.white};
  }
  img {
    border-radius: 50%;
  }
  p {
    margin-bottom: 12px;
  }
`;
const ModalButtonClose_Copy = styled(ModalButtonClose)`
  background: inherit;
  border: 1px solid ${GREYS.silver};
  color: ${GREYS.white};
  :hover {
  background:${BRAND.secondary}
`;

const styledH = styled(H)`
  height: 24px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
`;

const EYE_OL = styled(EYE)`
  padding-right: 8px;
  top: 170px;
`;

const CR_OL = styled(C_OL)`
  justify-content: end;
  margin: -24px 0 24px 0;
`;

const MB_OL = styled(MB)`
  color: ${GREYS.white};
`;
export {
  styledH as H,
  D_OL as D,
  S_OL as S,
  C_OL as C,
  E,
  MB_OL as MB,
  MStyles_OL as MStyles,
  ModalButtonClose_Copy as ModalButtonClose,
  EYE_OL as EYE,
  Image,
  L,
  J,
  CR_OL as CR,
};
