import styled, { css } from 'styled-components';

const MC_OL = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  z-index: 9999999;
  ${({ showModal }) =>
    showModal
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
  transform: translate3d(0, 0, 0);
`;

export { MC_OL as MC };
